import { TagAPI } from "@/modules/tag/model/TagAPI"
import { Tag, UnsavedTag } from "../tag/model/Tag";
import { v4 as uuidv4 } from 'uuid'

class TagAPIMock implements TagAPI {

    get(url: string): Promise<Tag[]> {
        return new Promise((resolve, _) => { resolve([]) })
    }
    post(url: string, data: UnsavedTag): Promise<Tag> {
        const tag: Tag = {... data, id: uuidv4()}
        return new Promise((resolve, _) => { resolve(tag) })
    }

    put(url: string, data: Tag): Promise<Tag> {
        return new Promise((resolve, _) => { resolve(data) })
    }
    delete(url: string): Promise<boolean> {
        return new Promise((resolve, _) => { resolve(true) })
    }

}

export { TagAPIMock }