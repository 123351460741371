import { Tag, UnsavedTag } from "@/modules/tag/model/Tag"

type Result<T, E = Error> =
  | { success: true; value: T }
  | { success: false; error: E };

interface TagAPI {
  get(url: string): Promise<Tag[]>
  post(url: string, data: UnsavedTag): Promise<Tag>
  put(url: string, data: Tag): Promise<Tag>
  delete(url: string): Promise<boolean>
}

interface TagError {
  resolve: string
}

class TagExists extends Error implements TagError {
  resolve: string
  constructor() {
      super()
      this.name = "TagExists"
      this.message = "The tag already exists"
      this.resolve = ""
  }
}

interface TagHandler {
  isValid(tag: Tag | UnsavedTag): boolean
  fetchAll(): void
  create(tag: Tag): void
  update(tag: Tag): void
  deleteTag(tag: Tag): void

  resetTag(tag: Tag): void
}


export { Result, TagAPI, TagError, TagExists, TagHandler }