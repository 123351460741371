import { Tag } from "brandi";
import { UnsavedTag } from "./Tag";

interface TagCreator {
    createDefaultUnsavedTag(): UnsavedTag
}

class TagFactory implements TagCreator {
    private borderColors = ["#1A82BE", "#096F49"];


    createDefaultUnsavedTag(): UnsavedTag {
        const tag: UnsavedTag = {
            title: "",
            description: "",
            style: {
                border: this.chooseRandomColor(),
                background: "#F3F3F3",
                color: "#333333",
            },
        };

        return tag
    }

    private chooseRandomColor() {
        return this.borderColors[Math.floor(Math.random() * this.borderColors.length)];
    }
}

export { TagCreator, TagFactory }