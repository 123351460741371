import { token } from 'brandi'
import { HTTPHandler } from '@/modules/infrastructure/http'
import { AuthAPI, AuthHandler, TokenRefresher } from '@/modules/authentication/AuthAPI'
import { TagAPI, TagHandler } from '@/modules/tag/model/TagAPI'
import { TagCreator } from '@/modules/tag/model/TagFactory'
import { ItemAPI, ItemHandler } from '@/modules/item/model/ItemAPI'
import { ItemCreator } from '@/modules/item/model/ItemFactory'



export const TOKENS = {
    http: token<HTTPHandler>('httpHandler'),
    authAPIService: token<AuthAPI>('authAPI'),
    tagAPIService: token<TagAPI>('tagAPI'),
    itemAPIService: token<ItemAPI>('itemAPI'),

    authService: token<AuthHandler>('authHandler'),
    tokenRefresher: token<TokenRefresher>('tokenRefresher'),
    
    tagService: token<TagHandler>('tagService'),
    itemService: token<ItemHandler>('itemService'),

    tagFactory: token<TagCreator>('tagFactory'),
    itemFactory: token<ItemCreator>('itemFactory')
}