import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

import { PersistencePiniaPlugin } from './system/PersistencePiniaPlugin'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'


const pinia = createPinia()
const app = createApp(App)


app.use(pinia)
app.use(router)
app.use(Quasar, quasarUserOptions)

pinia.use(PersistencePiniaPlugin)

app.mount('#app')


