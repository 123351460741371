import { HTTPError, HTTPHandler } from "@/modules/infrastructure/http";
import { Tag, UnsavedTag } from "@/modules/tag/model/Tag"
import { injected } from "brandi";
import { TOKENS } from "@/system/tokens";
import { ItemAPI } from "../item/model/ItemAPI";
import { Item } from "@/modules/item/model/Item"

class ItemAPIService implements ItemAPI {

  private http: HTTPHandler

  constructor(http: HTTPHandler) {
    this.http = http
  }

  async get(url: string): Promise<Item[]> {
    const result = await this.http.get(url)
    return result.data
  }

  async post(url: string, data: UnsavedTag): Promise<Item> {
    const result = await this.http.post(url, data)
    return result.data
  }

  async put(url: string, data: Item): Promise<Item> {
    const result = await this.http.put(url, data)

    if (result.status > 299)
      throw new HTTPError("Could not update item.")

    return result.data
  }

  async delete(url: string): Promise<void> {
    const result = await this.http.delete(url)
    if (result.status > 299)
      throw new HTTPError("Could not delete item.")

  }
}

injected(ItemAPIService, TOKENS.http)

export { ItemAPIService }