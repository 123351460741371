import axios, { AxiosResponse, AxiosError, AxiosInstance } from "axios";
import { TokenRefresher } from "@/modules/authentication/AuthAPI";
import { injected } from "brandi";
import { AuthenticationService } from "../authentication/AuthService";
import { TOKENS } from "@/system/tokens";

interface HTTPHandler {
  get(url: string): Promise<HTTPResponse>
  post(url: string, data: any): Promise<HTTPResponse>
  put(url: string, data: any): Promise<HTTPResponse>
  delete(url: string): Promise<HTTPResponse>
}


interface HTTPResponse {
  status: number
  message?: string
  data?: any
}

class HTTPService implements HTTPHandler {
  http: AxiosInstance

  constructor() {
    this.http = axios.create({
      withCredentials: true,
      baseURL: process.env.VUE_APP_API_ENDPOINT,
      headers: {
        'Content-type': 'application/json'
      }
    })
  }

  get(url: string): Promise<HTTPResponse> {
    return this.http.get(url)
            .then(this.toHTTPResonse)
            .catch(this.errorToHTTPResponse)
  }

  post(url: string, data: any): Promise<HTTPResponse> {
    return this.http.post(url, data)
            .then(this.toHTTPResonse)
            .catch(this.errorToHTTPResponse)
  }
  put(url: string, data: any): Promise<HTTPResponse> {
    return this.http.put(url, data)
            .then(this.toHTTPResonse)
            .catch(this.errorToHTTPResponse)
  }
  delete(url: string): Promise<HTTPResponse> {
    return this.http.delete(url)
            .then(this.toHTTPResonse)
            .catch(this.errorToHTTPResponse)
  }

  toHTTPResonse = (res: AxiosResponse<any, any>): HTTPResponse  => {
    return {status: res.status, message: res.statusText, data: res.data} 
  }

  errorToHTTPResponse = (error: AxiosError): HTTPResponse => {
    if (error instanceof AxiosError) {
        return {status: error.response?.status ?? 0, message: error.request.statusText, data: undefined}
    } else {
        throw error
    }
  }

}


class APIError extends Error {
  constructor() {
    super("Unexpected Error");
    this.name = "APIError"
  }
}

class HTTPError extends APIError {
  constructor(message: string) {
    super()
    this.name = "HTTPError"
    this.message = message
  }
}


export { HTTPHandler, HTTPResponse, HTTPService, HTTPError}