<template>
  <q-input
    filled
    :style="`background-color: ${color}`"
    v-model="color"
    @click="showColorDialog = true"
    :label="label"
  />
  <q-dialog
    v-model="showColorDialog"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="" style="width: 300px">
      <q-card-section>
        <div class="text-h6">Persistent</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-color
          v-model="color"
          default-view="palette"
          class="my-picker"
          :no-header-tabs="true"
          format-model="hex"
        />
      </q-card-section>
      <q-card-section class="text-center">
        <q-btn flat rounded label="Done" v-close-popup />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, ref } from "vue"

const showColorDialog = ref(false)

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: String,
  label: String,
});

const color = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit("update:modelValue", value);
  },
});
</script>
