import script from "./ColorInput.vue?vue&type=script&setup=true&lang=js"
export * from "./ColorInput.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QDialog,QCard,QCardSection,QColor,QBtn});qInstall(script, 'directives', {ClosePopup});
