import { Container, tagged } from "brandi";
import { TOKENS } from "./tokens";

import { AuthAPIService } from "@/modules/infrastructure/AuthApiService";
import { HTTPService } from "@/modules/infrastructure/http";
import { TagAPIService } from "@/modules/infrastructure/TagApiService";
import { TagService } from "@/modules/tag/model/TagService";
import { TAGS } from "./tags";
import { TagAPIMock } from "@/modules/infrastructure/TagAPIMock";
import { TagFactory } from "@/modules/tag/model/TagFactory";
import { AuthenticatedHTTPService, AuthenticationService } from "@/modules/authentication/AuthService";
import { DefaultTokenRefresher } from "@/modules/infrastructure/DefaultTokenRefresher";
import { ItemAPIService } from "@/modules/infrastructure/ItemAPIService";
import { ItemAPIMock } from "@/modules/infrastructure/ItemAPIMock";
import { ItemService } from "@/modules/item/model/ItemService";
import { ItemFactory } from "@/modules/item/model/ItemFactory";



if (process.env.OFFLINE)  {
    tagged(TagService, TAGS.offline)
    tagged(ItemService, TAGS.offline)
}


const container = new Container()

/// INFRA
container
    .bind(TOKENS.http)
    .toInstance(HTTPService)
    .inTransientScope()

container
    .when(TagAPIService)
    .bind(TOKENS.http)
    .toInstance(AuthenticatedHTTPService)
    .inTransientScope()

container
    .when(ItemAPIService)
    .bind(TOKENS.http)
    .toInstance(AuthenticatedHTTPService)
    .inTransientScope()

container
    .bind(TOKENS.tokenRefresher)
    .toInstance(DefaultTokenRefresher)
    .inTransientScope()


/// AUTH

container
    .bind(TOKENS.authAPIService)
    .toInstance(AuthAPIService)
    .inTransientScope()

container
    .bind(TOKENS.authService)
    .toInstance(AuthenticationService)
    .inTransientScope()


/// ITEM
container
    .bind(TOKENS.itemAPIService)
    .toInstance(ItemAPIService)
    .inTransientScope()

container
    .when(TAGS.offline)
    .bind(TOKENS.itemAPIService)
    .toInstance(ItemAPIMock)
    .inTransientScope()

container
    .bind(TOKENS.itemService)
    .toInstance(ItemService)
    .inTransientScope()

container
    .bind(TOKENS.itemFactory)
    .toInstance(ItemFactory)
    .inTransientScope()


/// TAG
container
    .bind(TOKENS.tagAPIService)
    .toInstance(TagAPIService)
    .inTransientScope()

container
    .when(TAGS.offline)
    .bind(TOKENS.tagAPIService)
    .toInstance(TagAPIMock)
    .inTransientScope()

container
    .bind(TOKENS.tagService)
    .toInstance(TagService)
    .inTransientScope()

container
    .bind(TOKENS.tagFactory)
    .toInstance(TagFactory)
    .inTransientScope()



export { container }