<template>
  <div class="row justify-center">

    <q-card flat bordered class="col-md-8 col-xl-6 q-pa-lg q-ma-lg text-body1">
      <q-card-section class="row text-left">
        <div class="col-sm-6">
          <p class="text-h6">
            A POWERFUL NEW TOOL FOR STORING INFORMATION
          </p>

          <p>
            Take control of the Internet by organizing content as you want it!
          </p>

          <p>
            Tag content with your own custom tags. Search, filter and find everyting you've ever tagged.
          </p>
        </div>
        <div class="col text-center">
          <TagView v-for="tag in tags" :key="tag" :tag="tag" />
        </div>
      </q-card-section>
    </q-card>


    <q-card flat bordered class="col-md-8 col-xl-6 q-pa-lg q-ma-lg text-body1">
      <q-card-section class="row text-left">
        <div class="col-xs-12 col-sm-6  q-mb-xl">
          <q-input rounded outlined v-model="textInputValue" disable label="Save site" class="q-ma-lg" />

          <div class="w-full text-center">
            <TagView :tag="tags[0]" />
            <TagView :tag="tags[1]" />
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 text-center">
          <p  class="text-h6">
            TAG CONTENT
          </p>
          <p  class="text-2xl font-bold">
            Organize items by combining tags.
          </p>
          <p class="text-2xl">
            Create custom tags and use them to organize any content that you want to store.
          </p>
        </div>
      </q-card-section>
    </q-card>



    <q-card flat bordered class="row col-md-8 col-xl-6 q-pa-lg q-ma-lg text-body1">
      <q-card-section class="row text-left">
        <div class="col-xs-12 col-sm-6">
        <p  class="text-h6">
          FILTER BY TAG
        </p>
        <p class="text-2xl">
          Easily find the content you have stored by filter on tags.
          More tags will narrow the search.
        </p>
      </div>
      <div class="col-xs-12 col-sm-6 text-center">
          <q-select
            v-model="selectedTags"
            outlined
            rounded
            disable
            multiple
            label="Filter by tag"
            class="q-ma-lg" 
          >
            <template v-slot:selected-item="scope">
              <TagView :tag="scope.opt" :removable="true" />
            </template>
          </q-select>


          <div class="flex justify-between q-pa-sm items-center">
            <span>Osso Buco alla Milanese</span>
            <div>
              <TagView :tag="tags[29]" />
              <TagView :tag="tags[2]" />
            </div>
          </div>

          <div class="flex justify-between q-pa-sm items-center">
            <span>The best Fettuccine Alfredo</span>
            <div>
              <TagView :tag="tags[31]" />
              <TagView :tag="tags[2]" />
              <TagView :tag="tags[29]" />
            </div>
          </div>

          <div class="flex justify-between q-pa-sm items-center">
            <span>Real Italian Margherita Pizza</span>
            <div>
              <TagView :tag="tags[2]" />
              <TagView :tag="tags[29]" />
              <TagView :tag="tags[19]" />
            </div>
          </div>
      </div>
      </q-card-section>
    </q-card>






  </div>

</template>



<script setup>
import TagView from "@/modules/tag/components/Tag.vue";

import { useAuthStore } from "@/modules/authentication/AuthService"

import { container } from "@/system/container"
import { TOKENS } from "@/system/tokens"
import { computed, onMounted, ref } from 'vue'
import { Tag } from "@/modules/tag/model/Tag"
import FilterInput from "@/components/FilterInput.vue";
import { tag } from "brandi";


const authService = container.get(TOKENS.authService)

const slide = ref(1)
const autoplay = ref(true)


const textInputValue = ref('https://mightag.com')
const inputSearch = ref('Filter by tag')

const tags = [{ id: '', title: 'M I G H T', description: '', style: { border: '#1E82BE', background: 'white' } },
      { id: '', title: 'T A G', description: '', style: { border: '#0A6E46', background: 'white' } },
      { id: '', title: 'Recipe', description: '', style: { border: '#03BFF6', background: 'white' } },
      { id: '', title: 'Architecture', description: '', style: { border: '#0276fD', background: 'white' } },
      { id: '', title: 'Jeans', description: '', style: { border: '#030303', background: 'white' } },
      { id: '', title: 'Work', description: '', style: { border: '#dec3c3', background: 'white' } },
      { id: '', title: 'Project X', description: '', style: { border: '#009688', background: 'white' } },
      { id: '', title: 'TODO', description: '', style: { border: '#f37736', background: 'white' } },
      { id: '', title: 'Readlist', description: '', style: { border: '#0057e7', background: 'white' } },
      { id: '', title: 'School', description: '', style: { border: '#edc951', background: 'white' } },
      { id: '', title: 'Warhammer', description: '', style: { border: '#21c34f', background: 'white' } },
      { id: '', title: 'Python', description: '', style: { border: '#02ec4f', background: 'white' } },
      { id: '', title: 'Kamado', description: '', style: { border: '#abb90e', background: 'white' } },
      { id: '', title: 'Startup', description: '', style: { border: '#eeee44', background: 'white' } },
      { id: '', title: 'Wood working', description: '', style: { border: '#00e172', background: 'white' } },
      { id: '', title: 'Workout', description: '', style: { border: '#7743ae', background: 'white' } },
      { id: '', title: 'Shoes', description: '', style: { border: '#ff2951', background: 'white' } },
      { id: '', title: 'DONG', description: '', style: { border: '#21ac21', background: 'white' } },
      { id: '', title: 'Pics', description: '', style: { border: '#3429fc', background: 'white' } },
      { id: '', title: 'Pizza', description: '', style: { border: '#AA3333', background: 'white' } },
      { id: '', title: 'Chinese Food', description: '', style: { border: '#edc951', background: 'white' } },
      { id: '', title: 'Sport', description: '', style: { border: '#343434', background: 'white' } },
      { id: '', title: 'Stocks', description: '', style: { border: '#0012f2', background: 'white' } },
      { id: '', title: 'Health', description: '', style: { border: '#ed2051', background: 'white' } },
      { id: '', title: 'Dividend', description: '', style: { border: '#123123', background: 'white' } },
      { id: '', title: 'Music', description: '', style: { border: '#ceea32', background: 'white' } },
      { id: '', title: 'Steam', description: '', style: { border: '#dead00', background: 'white' } },
      { id: '', title: 'Gardening', description: '', style: { border: '#00beef', background: 'white' } },
      { id: '', title: 'Tutorial', description: '', style: { border: '#feade0', background: 'white' } },
      { id: '', title: 'Italy', description: '', style: { border: '#438099', background: 'white' } },
      { id: '', title: 'Wine', description: '', style: { border: '#651e3e', background: 'white' } },
      { id: '', title: 'Pasta', description: '', style: { border: '#CCCC00', background: 'white' } }
    ]

const selectedTags = [
      { id: '', title: 'Recipe', description: '', style: { border: '#03BFF6', background: 'white' } },
      { id: '', title: 'Italy', description: '', style: { border: '#438099', background: 'white' } },
]
</script>

<style lang="sass" scoped>
.my-card
  max-width: 300px
</style>