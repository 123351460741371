import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { AuthAPI, AuthHandler, TokenRefresher } from "@/modules/authentication/AuthAPI"
import { injected } from "brandi";
import { TOKENS } from "@/system/tokens";
import { HTTPService } from "../infrastructure/http";
import router from '@/router'

const useInternalAuthStore = defineStore('InternalAuth', () => {

  //
  //      STATE
  //
  const isAuthenticated = ref(false)
  
  const csrf = ref()

  //
  //      ACTIONS
  // 
  function setAuthentication(status: boolean, csrfValue?: string) {
    isAuthenticated.value = status
    if (status)  {
      csrf.value = csrfValue
    } else {
      csrf.value = undefined
    }
  }

    return { isAuthenticated, csrf, setAuthentication }
})



const useAuthStore = defineStore('auth', () => {

  const internal = useInternalAuthStore()

  //
  //      GETTERS
  //  
  const isAuthenticated = computed(() => {
    return internal.isAuthenticated
  })

  return { isAuthenticated }
})




class AuthenticationService implements AuthHandler {

  private http: AuthAPI
  private store = useInternalAuthStore()

  constructor(http: AuthAPI) {
    this.http = http
  }

  async logout(): Promise<string> {
    const result = await this.http.post('/logout')
    
    this.store.setAuthentication(false)
    return result
  }

  async loginStart(): Promise<string> {
    console.log('LOGIN START')
    const url = await this.http.post('/login/start')

    return url
  }  

  async refreshToken(): Promise<boolean> {
    try {
      const result = await this.http.requestToken('/refresh', undefined)
      console.log("RESULT", result)
      this.store.setAuthentication(result.authenticated, result.csrf)
      return true
    } catch (error) {
      this.store.setAuthentication(false)
      return false
    }
  }


  async loginEnd(url: string): Promise<boolean> {

    const data = JSON.stringify({ pageUrl: url })

    try {
      const result = await this.http.requestToken('/login/end', data)
      this.store.setAuthentication(result.authenticated, result.csrf)
      return result.handled ?? false
    } catch {
      this.store.setAuthentication(false, undefined)
      return false
    }

  }
}

injected(AuthenticationService, TOKENS.authAPIService)

class AuthenticatedHTTPService extends HTTPService {
  tokenRefresher: TokenRefresher
  private store = useInternalAuthStore()


  constructor(tokenRefresher: TokenRefresher) {
    super()

    this.tokenRefresher = tokenRefresher

    this.setInterceptor()
  }

  
  private setInterceptor = () => {

    if (this.toHTTPResonse === undefined) {
      return
    }

    this.http.interceptors.response.use((config) => {
      return config;
    }, async (error) => {
      const originalRequest = error.config
      if (error.response?.status == 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const refrehsed = await this.tokenRefresher.refreshToken()
        if (refrehsed) {
          return this.http.post(originalRequest)
        } else {
          this.store.setAuthentication(false, undefined)
          router.replace({path: "/login"})

        }
      }
      return Promise.reject(error)
    })

  }
}



injected(AuthenticatedHTTPService, TOKENS.tokenRefresher)



export { useAuthStore, AuthenticationService, AuthenticatedHTTPService }