import { ItemAPI } from "../item/model/ItemAPI";
import { Item, UnsavedItem } from "../item/model/Item";
import { v4 as uuidv4 } from 'uuid'


class ItemAPIMock implements ItemAPI {

  get(url: string): Promise<Item[]> {
    return new Promise((resolve, _) => resolve([]))
  }

  post(url: string, data: UnsavedItem): Promise<Item> {
    const item: Item = {...data, id: uuidv4()} 
    return new Promise((resolve, _) => resolve(item))
  }

  put(url: string, data: Item): Promise<Item> {
    return new Promise((resolve, _) => resolve(data));
  }

  delete(url: string): Promise<void> {
    return new Promise((resolve, _) => resolve())
  }
}

export { ItemAPIMock };
