<template>
  <q-header elevated class="bg-white text-primary">
    <q-toolbar class="justify-center">
      <div class="col-md-10 col-xl-8">
        <div class="row justify-center">
          <div class="row col-md-8 col-xl-6">
            <q-toolbar-title>
              <ToolbarLogo />
            </q-toolbar-title>

            <q-btn
              v-if="store.isAuthenticated"
              flat
              round
              dense
              icon="more_vert"
            >
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable v-close-popup @click="logout()">
                    <q-item-section>Log out</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
            <q-btn v-else flat round @click="login()"> Sign in </q-btn>
          </div>
        </div>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script setup>
import ToolbarLogo from "@/components/ToolbarLogo.vue";
import { useAuthStore } from "@/modules/authentication/AuthService";
import router from "@/router";
import { container } from "@/system/container";
import { TOKENS } from "@/system/tokens";
import { onMounted, ref } from "@vue/runtime-core";

const authService = container.get(TOKENS.authService);
const store = useAuthStore();

const login = () => {
  authService.loginStart().then((result) => {
    window.location.href = result.authorizationRequestUrl;
  });
};
const logout = () => {
  authService.logout().then((result) => {
    window.location.href = result.url;
  });
};
</script>

<style lang="scss">
header {
  display: flex;
  justify-content: space-between;
}
</style>
