import { PiniaPluginContext } from "pinia"

function persistencePiniaPlugin(context: PiniaPluginContext) {
  const state = JSON.parse(localStorage.getItem('piniaState') as string)
  context.store.$state = state

  context.store.$subscribe(() => {
    const jsonState = JSON.stringify(context.store.$state)
    localStorage.setItem('piniaState', jsonState)
  })
}

export { persistencePiniaPlugin as PersistencePiniaPlugin }

