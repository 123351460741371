import script from "./Tag.vue?vue&type=script&lang=ts&setup=true"
export * from "./Tag.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChip,QTooltip});
