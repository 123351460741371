import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { injected } from "brandi";
import { TOKENS } from "@/system/tokens";
import { Item } from "@/modules/item/model/Item";
import { ItemAPI, ItemHandler } from "./ItemAPI";
import { Tag } from "@/modules/tag/model/Tag";

const useInternalItemStore = defineStore("internalItemStore", () => {
  //
  //      STATE
  //
  const items = ref<Item[]>([]);

  //
  //      GETTERS
  //
  const allItems = computed(() => items.value);

  const indexOfItem = (item: Item): number => {
    return items.value.findIndex((tmp) => tmp.id === item.id);
  };

  //
  //      ACTIONS
  //
  const add = (item: Item) => {
    items.value.push(item)
  };

  const update = (item: Item) => {
    remove(item)
    items.value.push(item);
  };


  const remove = (item: Item) => {
    const index = items.value.findIndex((i) => i.id === item.id)
    if (index > -1) 
      items.value.splice(index, 1);
  };

  return { allItems, add, update, remove, indexOfItem }
});




const useItemStore = defineStore("itemStore", () => {
  const internal = useInternalItemStore();

  //
  //      GETTERS
  //
  const allItems = computed(() => internal.allItems)

  const filterOnTags = (tags: Tag[]) => 
  computed<Item[]>((): Item[] => {
    if (tags.length === 0) 
      return allItems.value
    
    return internal.allItems.filter(item => {
      return tags.every((tag => item.tags.findIndex(t => t.id === tag.id) > -1))
    })
  })

  const itemsWithTag = (tag: Tag): Item[] => {
    return internal.allItems.filter(item => { 
      return item.tags.findIndex(t => t.id === tag.id) > -1
    })
  }

  return { allItems, filterOnTags, itemsWithTag }
});

class ItemService implements ItemHandler {
  private http: ItemAPI
  private baseURL = "/api/items"

  private store = useInternalItemStore();


  constructor(http: ItemAPI) {
    this.http = http;
  }

  async fetchAll() {
    const result = await this.http.get(this.baseURL);
    for (const item of result) {
      if (this.store.indexOfItem(item) === -1) {
        this.store.add(item)
      }
    }
  }
  
  async create(item: Item) {
    this.validate(item)
    const result = await this.http.post(this.baseURL, item);
    this.store.add(result)
  }

  async update(item: Item) {
    const result = await this.http.put(`${this.baseURL}/${item.id}`, item);
    this.store.update(result);
  }

  async remove(item: Item) {
    const result = await this.http.delete(`/api/items/${item.id}`);
    this.store.remove(item);
  }

  validate(item: Item) {
    if (item.title.length < 1) {
      throw new Error("Item must have a title")
    }

    if (item.url.length < 1) {
      throw new Error("Item must have an URL")
    }

    if (item.tags.length < 1) {
      throw new Error("Item must have at least one tag")
    }

  }
}

injected(ItemService, TOKENS.itemAPIService);

export { useItemStore, ItemService };
