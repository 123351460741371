import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { Tag, UnsavedTag } from "./Tag";
import { TagAPI, TagHandler } from "./TagAPI";
import { injected } from "brandi";
import { TOKENS } from "@/system/tokens";
import { UnsavedItem } from "@/modules/item/model/Item";

const useInternalTagStore = defineStore("InternalTag", () => {
  //
  //      STATE
  //
  const tags = ref<Tag[]>([]);

  //
  //      GETTERS
  //

  const allTags = computed(() => tags.value);

  const indexOfTag = (tag: Tag): number => {
    return tags.value.findIndex((tmp) => tmp.id === tag.id);
  };

  const hasTag = (tag: Tag | UnsavedTag): boolean => {
    if (!("id" in tag))
      return false
    return tags.value.some((t) => {
      if (t.title === tag.title) {
        return t.id !== tag.id;
      }
      return false;
    });
  };

  //
  //      ACTIONS
  //
  const updateTag = (tag: Tag) => {
    const index = indexOfTag(tag);
    if (index > -1) {
      tags.value.splice(index, 1);
      addTag(tag)
    }
  };

  const addTag = (tag: Tag) => {
    tags.value.push(tag);
  };


  const deleteTag = (tag: Tag) => {
    const index = tags.value.indexOf(tag);
    tags.value.splice(index, 1);
  };

  return { tags, allTags, hasTag, updateTag, addTag, deleteTag, indexOfTag };
});

const useTagStore = defineStore("tag", () => {
  const internal = useInternalTagStore();

  //
  //      GETTERS
  //

  const filterTags = computed(() =>
    (filter: string) => {
      if (filter === "") {
        return internal.allTags
      }

      const result = internal.allTags.filter((tag) => {
        return tag?.title.toLocaleLowerCase().indexOf(filter) > -1
      })

      return result

    })
   

  const findTag = (title: string): Tag | undefined => {
    return internal.allTags.find((t) => t.title === title)
  }

  const tagForID = computed(() => (id: string) => {
    const original = internal.allTags.find((elem: Tag) => elem.id === id);
    return Object.assign({}, original);
  });

  return { findTag, filterTags, tagForID };
});

class TagService implements TagHandler {
  private http: TagAPI;

  private store = useInternalTagStore();

  constructor(http: TagAPI) {
    this.http = http;
  }


  async fetchAll() {
    const result = await this.http.get("/api/tags");
    console.log(result)
    for (const tag of result) {
      if (this.store.indexOfTag(tag) === -1) {
        this.store.addTag(tag);
      }
    }
  }

  resetTag(tag: Tag) {
    this.store.updateTag(tag);
  }

  async create(tag: Tag) {
    this.validate(tag);
    const result = await this.http.post("/api/tags", tag);
    this.store.addTag(result);
  }

  async update(tag: Tag) {
    this.validate(tag);
    const result: Tag = await this.http.put(`/api/tags/${tag.id}`, tag);
    this.store.updateTag(result);
  }

  async deleteTag(tag: Tag) {
    
    const success = await this.http.delete(`/api/tags/${tag.id}`);
    console.log("delete", success);
    if (success) {
      this.store.deleteTag(tag);
    }
  }
  
  isValid(tag: Tag | UnsavedTag): boolean {
    try {
      this.validate(tag)
      return true
    } catch {
      return false
    }
  }
  
  validate(tag: Tag | UnsavedTag) {
    const reservedTitles = ["not", "and", "or"];
    if (tag.title.length < 2) {
      throw new Error(
        `Tag may not be named "${tag.title}". Must be at least two characters.`
      );
    }
    if (!tag.title) {
      throw new Error("Tag requires a title");
    }
    if (reservedTitles.includes(tag.title.toLowerCase())) {
      throw new Error(
        `Tag may not be named "${tag.title}". "${tag.title}" is a reserved word`
      );
    }

    if (/^[^a-zåäöA-ZÅÄÖ0-9_-]+$/.test(tag.title)) {
      throw new Error(
        `Tag may not be named "${tag.title}". Only letters and numbers allowed`
      );
    }

    if (this.store.hasTag(tag)) {
      throw new Error(`Tag named "${tag.title}" already exists`);
    }
  }

}

injected(TagService, TOKENS.tagAPIService);

export { useTagStore, TagService };
