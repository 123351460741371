import { HTTPHandler, HTTPResponse } from "@/modules/infrastructure/http";
import { TagAPI, TagExists } from "@/modules/tag/model/TagAPI"
import { Tag, UnsavedTag } from "@/modules/tag/model/Tag"
import { injected } from "brandi";
import { TOKENS } from "@/system/tokens";

class TagAPIService implements TagAPI {

  private http: HTTPHandler

  constructor(http: HTTPHandler) {
    this.http = http
  }


  async get(url: string): Promise<Tag[]> {
    const result = await this.http.get(url)
    console.log("FETCH ALL", result)
    const tag: Tag[] = result .data
    return tag
  }

  async post(url: string, data: UnsavedTag): Promise<Tag> {
    const result = await this.http.post(url, data)
    
    if (result.status === 409) {
      throw new TagExists()
    }
    const tag: Tag = result .data
    return tag
  }

  async put(url: string, data: Tag): Promise<Tag> {
    const result = await this.http.put(url, data)
    const tag: Tag = result .data
    return tag
  }

  async delete(url: string): Promise<boolean> {
    const result = await this.http.delete(url)
    return result.status < 299
  }

}


injected(TagAPIService, TOKENS.http)

export { TagAPIService }