<template>
  <div class="row text-h6 justify-center q-my-lg">
    <span
      class="segment-button rounded-left q-pa-md"
      :class="{ selected: itemsIsActive }"
      @click="itemsIsActive = true"
    >
      Keeps
    </span>
    <span
      class="segment-button rounded-right q-pa-md"
      :class="{ selected: !itemsIsActive }"
      @click="itemsIsActive = false"
    >
      Tags
    </span>
  </div>

  <div class="q-pa-md q-my-xl bg-white rounded-left rounded-right">
    <component :is="itemsIsActive ? ItemPage : TagPage" />
  </div>
</template>

<script setup>
import TagPage from "@/pages/TagPage.vue";
import ItemPage from "@/pages/ItemsPage.vue";
import { onMounted, ref } from "vue";
import { container } from "@/system/container";
import { TOKENS } from "@/system/tokens";

const itemsIsActive = ref(true);

onMounted(() => {
  const tagService = container.get(TOKENS.tagService)
  const itemService = container.get(TOKENS.itemService)

  tagService.fetchAll()
  itemService.fetchAll()
})
</script>

<style lang="sass" scoped>
.segment-button
  width: 100%
  max-width: 17rem
  background: white


.selected
  background: $green-5
  color: white

.segment-button:hover
  background: $green-4
  color: white


.rounded-left
  border-top-left-radius: 2rem
  border-bottom-left-radius: 2rem

.rounded-right
  border-top-right-radius: 2rem
  border-bottom-right-radius: 2rem
</style>
