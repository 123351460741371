import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { useAuthStore } from '@/modules/authentication/AuthService'
import Main from '@/pages/Main.vue'
import LandingPage from '@/pages/LandingPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/login',
    name: 'Login',
    component: LandingPage
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Auth guard
router.beforeEach((to, from, next) => {
  const auth =  useAuthStore()
  console.log('GO TO', to.name, auth.isAuthenticated)
  if (auth.isAuthenticated) {
    return next()
  } else {
    if (to.name !== 'Login') {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})
export default router
