import { UnsavedItem } from "./Item";


interface ItemCreator {
    createDefaultUnsavedItem(): UnsavedItem
}
class ItemFactory implements ItemCreator {

  createDefaultUnsavedItem (): UnsavedItem {
    return  {
      title: '',
      owner: '',
      url: '', 
      description: '',
      tags: []
    }
  }
}

export { ItemFactory, ItemCreator }