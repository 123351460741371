import { AuthAPI, AuthenticationResponse } from "@/modules/authentication/AuthAPI"
import { TOKENS } from "@/system/tokens"
import { injected } from "brandi"
import { HTTPError, HTTPHandler } from "./http"

class AuthAPIService implements AuthAPI {

  private http: HTTPHandler

  constructor(http: HTTPHandler) {
    this.http = http
  }


  async post(url: string ): Promise<string> {
    const result = await this.http.post(url, undefined)
    return result.data
  }
  async requestToken(url: string, data?: string): Promise<AuthenticationResponse> {
    const result = await this.http.post(url, data)

    if (result.status > 299) 
      throw new HTTPError("Login failed")

    return result.data
  }
}

injected(AuthAPIService, TOKENS.http)

export { AuthAPIService }